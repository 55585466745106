import {each} from 'lodash'
import store from '../store/index'
import EventBus , {events} from "@/lib/eventBus";
export const errorCodes = {
    unauthorized: 10401,
    mfaRequired: 20403,
    mfaNotValid: 20404
};
class Api {
    baseUrl = process.env.VUE_APP_API_BASE_URL;
    _basePath = '/admin/v1';
    axios = require('axios');
    _token = null;

    constructor(token = null) {
        this._token = token || store.getters['auth/accessToken'];
        this.axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';
        this.axios.defaults.baseURL = this.url + '/'

        this.axios.interceptors.response.use(response => response, error => {
            let response = error.response;
            let status = response.status;
            let data = response.data;

            if (response && status === 401 && data.error && data.error.code === errorCodes.unauthorized) {
                EventBus.$emit(events.auth.signout)
            }
            return Promise.reject(error);
        });
    }

    getQueryFilter(queryFields) {
        let query = '';

        each(queryFields, function (options, resource) {
            query += resource + ':';
            each(options, function (fields, option) {
                query += option + '{' + fields.join(',') + '};';
            });
        });

        return query;
    }

    get url() {
        return this.baseUrl + this._basePath
    }

    addAuthorization() {
        this.axios.defaults.headers.common['Authorization'] = `Bearer ${this.token}`;
    }

    get token() {
        return this._token;
    }

    set token(token) {
        this._token = token;
        this.addAuthorization();
    }

    login( username , password, code = null){
        let payload = {
            username: username,
            password: password,
        }
        if(code){
            payload.code = code;
        }
        return this.axios.post('/auth/signin', payload)
    }

    mfaGenerate(){
        this.addAuthorization()
        return this.axios.get('/mfa/generate')
    }
    mfaApprove(code) {
        this.addAuthorization()
        return this.axios.post('/mfa/approve', {
            code: code
        })
    }

    mfaDisable(){
        this.addAuthorization()
        return this.axios.post('/mfa/disable')
    }

    suppliers(filters = {}) {
        this.addAuthorization()
        return this.axios.get(`suppliers`, {
            params: filters
        })
    }


    supplier(id) {
        this.addAuthorization()
        return this.axios.get(`suppliers/${id}`)
    }

    supplierProducts(id, filters= {}){
        this.addAuthorization()
        filters.q = this.getQueryFilter({
            products: {
                fields: [
                    'id','name','tariffs','has_excluded_tariffs'
                ]
            }
        })
        return this.axios.get(`suppliers/${id}/products`, {
            params: filters
        })
    }

    updateSupplierProductDefaultExclude(exclude, productId){
        this.addAuthorization()
        return this.axios.get(`products/${productId}/${exclude ? 'exclude' : 'include'}`)
    }

    updateSupplier(id, supplier) {
        this.addAuthorization()
        return this.axios.put(`suppliers/${id}`, supplier)
    }

    getSupplierAvatarUploadUrl(id) {
        return `${this.url}/suppliers/${id}/logo`
    }


    users( filters = {}) {
        this.addAuthorization()

        let queryParams = {
            q: this.getQueryFilter({
                users: {
                    fields: [
                        'id',
                        'title',
                        'first_name',
                        'last_name', 'email',
                        'code',
                        'balance',
                        'social_facebook_id',
                        'social_google_id',
                        'verified',
                        'from_facebook',
                        'from_google',
                        'direct_debit',
                        'created_at'
                    ]
                }
            }),
            ...filters
        };
        return this.axios.get('users',  {
            params: queryParams
        })
    }

    user(id) {
        this.addAuthorization()

        const queryParams = {
            q: this.getQueryFilter({
                users: {
                    fields: [
                        'id',
                        'title',
                        'first_name',
                        'last_name', 'email',
                        'code',
                        'balance',
                        'social_facebook_id',
                        'social_google_id',
                        'verified',
                        'from_facebook',
                        'from_google',
                        'direct_debit',
                        'phone',
                        'contact_address',
                        'marketing_consent'
                    ]
                }
            }),
        }
        return this.axios.get(`users/${id}`, {
            params: queryParams
        })
    }

    properties(filters = {}) {
        this.addAuthorization()
        const queryParams = {
            q: this.getQueryFilter({
                properties: {
                    fields: [
                        'id',
                        'mpan',
                        'mprn',
                        'status',
                        'address_county',
                        'address_post_town',
                        'address_dependent_locality',
                        'address_double_dependent_locality',
                        'address_thoroughfare',
                        'address_dependent_thoroughfare',
                        'address_building_name_number',
                        'address_sub_building_name_number',
                        'address_free_text',
                        'postcode',
                        'recommendation',
                        'user'
                    ]
                },

            }),
            ...filters
        }
        return this.axios.get('properties', {
            params: queryParams,
        })
    }

    property(id) {
        this.addAuthorization()
        const queryParams = {
            q: this.getQueryFilter({
                properties: {
                    fields: [
                        'id',
                        'mpan',
                        'mprn',
                        'status',
                        'address_county',
                        'address_post_town',
                        'address_dependent_locality',
                        'address_double_dependent_locality',
                        'address_thoroughfare',
                        'address_dependent_thoroughfare',
                        'address_building_name_number',
                        'address_sub_building_name_number',
                        'address_free_text',
                        'postcode',
                        'recommendation',
                        'user'
                    ]
                }
            }),
        }
        return this.axios.get(`properties/${id}`, {
            params: queryParams
        })
    }

    switches(filters = {}){
        this.addAuthorization()

        const queryParams = {
            ...filters,
            q: this.getQueryFilter({
                recommendations: {
                    fields: [
                        'id',

                        //1st column
                        'user',
                        'property',

                        //2nd and 3rd columns
                        'current_supply',

                        //4th column
                        'recommended_product',
                        'recommended_tariff',
                        'annual_savings',
                        'annual_estimation',
                        'status',
                        'expected_switch_date',
                        'created_at'
                    ]
                },
                users: {
                    fields: [
                        'id',
                        'first_name',
                        'last_name',
                        'email',
                        'phone'
                    ]
                },
                properties:{
                    fields: [
                        'id',
                        'mpan',
                        'mprn',
                        'postcode',
                        'address',
                        'address_county',
                        'address_post_town',
                        'address_dependent_locality',
                        'address_double_dependent_locality',
                        'address_thoroughfare',
                        'address_dependent_thoroughfare',
                        'address_building_name_number',
                        'address_sub_building_name_number',
                        'address_free_text',
                    ]
                },
                suppliers: {
                    fields: ['id','name']
                },
                tariffs: {
                    fields: [
                        'id',
                        'term',
                        'payment_method',
                        'service_type',
                        'e7',
                        'prices'
                    ]
                }

            })
        }
        return this.axios.get(`switches`, {
            params: queryParams
        })
    }

    switchDetails(id){
        this.addAuthorization()

        const queryParams = {
            q: this.getQueryFilter({
                recommendations: {
                    fields: [
                        'id',

                        //1st column
                        'user',
                        'property',

                        //2nd and 3rd columns
                        'current_supply',

                        //4th column
                        'recommended_product',
                        'recommended_tariff',

                        'annual_savings',
                        'annual_estimation',

                        'status',
                        'expected_switch_date',
                        'notice',
                        'report_sent_at'
                    ]
                },
                users: {
                    fields: [
                        'id',
                        'first_name',
                        'last_name',
                        'email',
                        'phone'
                    ]
                },
                properties:{
                    fields: [
                        'id',
                        'mpan',
                        'mprn',
                        'postcode',
                        'address',
                        'address_town'
                    ]
                },
                suppliers: {
                    fields: ['id','name']
                },
                tariffs: {
                    fields: [
                        'id',
                        'term',
                        'payment_method',
                        'service_type',
                        'e7',
                        'prices'
                    ]
                }

            })
        }
        return this.axios.get(`switches/${id}`, {
            params: queryParams
        })
    }

    updateSwitch(id, action){
        this.addAuthorization()
        return this.axios.post(`switches/${id}/${action}`)
    }

    updateSwitchDate(id, date){
        this.addAuthorization()
        return this.axios.put(`switches/${id}`, {
            date: date
        })
    }
    updateMprn(id, mprn){
        this.addAuthorization()
        return this.axios.put(`switches/${id}`, {
            mprn: mprn
        })
    }

    tickets( filters = {}) {
        this.addAuthorization()

        let queryParams = {
            ...filters
        };
        return this.axios.get('tickets',  {
            params: queryParams
        })
    }
    ticketsStatusUpdate(id) {
        this.addAuthorization()
        return this.axios.get(`tickets/${id}/toggle`)
    }

    stats(){
        this.addAuthorization()
        return this.axios.get('stats')
    }
    better(filters = {}){
        this.addAuthorization()
        let queryParams = {
            ...filters
        };
        return this.axios.get('better',  {
            params: queryParams
        })
    }
    betterExportCsv(filters = {}){
        this.addAuthorization()
        let queryParams = {
            ...filters
        };
        return this.axios.get('better/export/csv',  {
            responseType: 'blob',
            params: queryParams
        })
    }

    survey() {
        return this.axios.get(`/survey`, {
            baseURL: this.baseUrl + '/v1'
        });
    }

    userRewards(id, filters = {}) {
        this.addAuthorization()
        const queryParams = {
            ...filters
        }
        return this.axios.get(`users/${id}/rewards`, {
            params: queryParams
        })
    }
    rewards(filters = {}) {
        this.addAuthorization()
        const queryParams = {
            ...filters
        }
        return this.axios.get(`rewards`, {
            params: queryParams
        })
    }
    userPayouts(id, filters = {}) {
        this.addAuthorization()
        const queryParams = {
            ...filters
        }
        return this.axios.get(`users/${id}/payouts`, {
            params: queryParams
        })
    }
    payouts(filters = {}) {
        this.addAuthorization()
        const queryParams = {
            q: this.getQueryFilter({
                payouts: {
                    fields: [
                        'id',
                        'amount',
                        'created_at',
                        'paid_at',
                        'user'

                    ]
                }
            }),
            ...filters
        }
        return this.axios.get(`payouts`, {
            params: queryParams
        })
    }

    payoutConfirm(id){
        this.addAuthorization()
        return this.axios.post(`payouts/${id}/confirm`);
    }

    payout(id){
        this.addAuthorization()
        const queryParams = {
            q: this.getQueryFilter({
                payouts: {
                    fields: [
                        'id',
                        'amount',
                        'created_at',
                        'paid_at',
                        'user'

                    ]
                }
            })
        }
        return this.axios.get(`payouts/${id}`, {
            params: queryParams
        });
    }

    registrations() {
        this.addAuthorization()
        const queryParams = {
            q: this.getQueryFilter({
                registrations: {
                    fields: [
                        'id',
                        'title',
                        'full_name',
                        'business_name',
                        'email',
                        'number',
                        'created_at',
                    ]
                }
            })
        }
        return this.axios.get(`registrations`, {
            params: queryParams
        })
    }

    changePassword(oldPassword, newPassword){
        this.addAuthorization()
        return this.axios.put('admin/password', {
            'old': oldPassword,
            'new': newPassword
        })
    }
    resendReport( id ){
        this.addAuthorization()
        return this.axios.get(`switches/${id}/send`)
    }
}

export default new Api();
